<script>
import appConfig from "../../src/app.config";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      socials: {
        facebook: appConfig.socials.facebook,
        instagram: appConfig.socials.instagram,
        youtube: appConfig.socials.youtube,
      }
    }
  },
}
</script>

<template>
  <div class="content">
    <div class="icons">
      <a v-if="socials.facebook" :href="socials.facebook" target="_blank" rel="noopener noreferrer">
        <img src="@/assets/images/facebook.svg" alt="Facebook" height="30" />
      </a>
      <a v-if="socials.instagram" :href="socials.instagram" target="_blank" rel="noopener noreferrer">
        <img src="@/assets/images/instagram.svg" alt="Instagram" height="30" />
      </a>
      <a v-if="socials.youtube" :href="socials.youtube" target="_blank" rel="noopener noreferrer">
        <img src="@/assets/images/youtube.svg" alt="Youtube" height="40" />
      </a>
    </div>
  </div>
</template>

<style>
  .content {
    margin-top: 15px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
  }

  .icons {
    position: absolute;
    bottom: 40px;
  }

  .icons a {
    padding: 5px;
  }
</style>